<script lang="ts">
	import { onMount } from 'svelte'

	import { Button } from '$lib/components/ui/button'
	import * as Dialog from '$lib/components/ui/dialog'
	import * as m from '$lib/paraglide/messages'
	import { getListedDialogStore } from '$lib/stores/dialogs'
	import { getSocials } from '$lib/utils/chainHelpers'
	import { phg } from '$lib/utils/posthog'

	import ChainSocial from '../chain-social.svelte'

	let dialogState: 'question' | 'team-member' | 'community-member' = 'question'

	const unsubscribe = getListedDialogStore.subscribe(({ open }) => {
		if (open === false) {
			dialogState = 'question'
		}
	})

	onMount(() => () => {
		unsubscribe()
	})
</script>

<Dialog.Root preventScroll bind:open={$getListedDialogStore.open}>
	<Dialog.Content>
		<Dialog.Header>
			<Dialog.Title class="font-bold">{m.get_listed_dialog_title()}</Dialog.Title>
		</Dialog.Header>

		{#if dialogState === 'question'}
			<div class="prose text-center sm:text-left">
				<p>{m.get_listed_dialog_question()}</p>
			</div>

			<div class="mt-1 flex items-center justify-center gap-4">
				<Button
					size="sm"
					variant="outline"
					on:click={() => {
						dialogState = 'community-member'

						phg?.capture('get_listed_dialog_community_member_instructions')
					}}
				>
					{m.get_listed_dialog_community_member()}
				</Button>

				<Button
					size="sm"
					variant="outline"
					on:click={() => {
						dialogState = 'team-member'

						phg?.capture('get_listed_dialog_blockchain_team_form')
					}}
				>
					{m.get_listed_dialog_team_member()}
				</Button>
			</div>
		{:else if dialogState === 'team-member'}
			<div class="prose">
				<p>
					<!-- eslint-disable-next-line svelte/no-at-html-tags -->
					{@html m.get_listed_dialog_team_member_instructions({
						link: 'https://docs.google.com/forms/d/e/1FAIpQLSfRL5XJ-DpK08adpggqjZb71ru0Og1bnnUM-34yTe8dWTNyPw/viewform?usp=sf_link',
					})}
				</p>
			</div>

			<div class="mt-1 flex items-center justify-stretch gap-6">
				<Button
					class="!w-full"
					size="sm"
					variant="outline"
					on:click={() => {
						dialogState = 'question'
					}}
				>
					{m.get_listed_dialog_back()}
				</Button>
			</div>
		{:else if dialogState === 'community-member'}
			<div class="prose">
				{#if $getListedDialogStore.chainConfig}
					{m.get_listed_dialog_specific_community_member_instructions({
						chain: $getListedDialogStore.chainConfig.name,
					})}
				{:else}
					{m.get_listed_dialog_general_community_member_instructions()}
				{/if}
			</div>

			{#if $getListedDialogStore.chainConfig}
				<div class="mt-1 flex flex-wrap items-center justify-center gap-2">
					{#each getSocials( $getListedDialogStore.chainConfig.socials, ['twitter', 'discord', 'reddit', 'telegram'] ) as { type, link }}
						<ChainSocial {link} size="24" {type} />
					{/each}
				</div>
			{/if}

			<div class="mt-1 flex items-center justify-stretch gap-6">
				<Button
					class="!w-full"
					size="sm"
					variant="outline"
					on:click={() => {
						dialogState = 'question'
					}}
				>
					{m.get_listed_dialog_back()}
				</Button>
			</div>
		{/if}
	</Dialog.Content>
</Dialog.Root>
