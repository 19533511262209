<script lang="ts">
	import * as Dialog from '$lib/components/ui/dialog'
	import * as m from '$lib/paraglide/messages'
	import { metricDialogStore } from '$lib/stores/dialogs'

	let title = ''
	let description = ''
	let text = ''

	$: {
		switch ($metricDialogStore.type) {
			case 'tps':
				title = m.tps_explanation_dialog_title()
				description = m.tps_explanation_dialog_description()
				text = m.tps_explanation_dialog_text()
				break

			case 'max-tps':
				title = m.max_tps_explanation_dialog_title()
				description = m.max_tps_explanation_dialog_description()
				text = m.max_tps_explanation_dialog_text()
				break

			case 'theor-tps':
				title = m.theor_tps_explanation_dialog_title()
				description = m.theor_tps_explanation_dialog_description()
				text = m.theor_tps_explanation_dialog_text()
				break

			case 'blocktime':
				title = m.blocktime_explanation_dialog_title()
				description = m.blocktime_explanation_dialog_description()
				text = m.blocktime_explanation_dialog_text()
				break

			case 'ttf':
				title = m.ttf_explanation_dialog_title()
				description = m.ttf_explanation_dialog_description()
				text = m.ttf_explanation_dialog_text()
				break

			case 'governance':
				title = m.governance_explanation_dialog_title()
				description = m.governance_explanation_dialog_description()
				text = m.governance_explanation_dialog_text()
				break

			case 'launch-date':
				title = m.launch_date_explanation_dialog_title()
				text = m.launch_date_explanation_dialog_text()
				break
		}
	}
</script>

<Dialog.Root preventScroll bind:open={$metricDialogStore.open}>
	<Dialog.Content>
		<Dialog.Header>
			<Dialog.Title>{title}</Dialog.Title>

			{#if !!description}
				<Dialog.Description class="links-inside">
					<!-- eslint-disable-next-line svelte/no-at-html-tags -->
					{@html description}
				</Dialog.Description>
			{/if}
		</Dialog.Header>

		<div class="prose dense scrollable overflow-auto text-muted-foreground">
			<p>
				<!-- eslint-disable-next-line svelte/no-at-html-tags -->
				{@html text}
			</p>
		</div>
	</Dialog.Content>
</Dialog.Root>
