<script lang="ts">
	import { onMount } from 'svelte'

	import { page } from '$app/stores'
	import { Button } from '$lib/components/ui/button'
	import { Separator } from '$lib/components/ui/separator'
	import * as m from '$lib/paraglide/messages'
	import { getListedDialogStore } from '$lib/stores/dialogs'
	import { phg } from '$lib/utils/posthog'
	import Discord from '~icons/simple-icons/discord'
	import Twitter from '~icons/simple-icons/x'

	export let isMobile = false

	let mobileMenu: Promise<typeof import('./mobile-menu.svelte')> | null = null

	onMount(() => {
		if ((isMobile || window.innerWidth <= 640) && !mobileMenu) {
			mobileMenu = import('./mobile-menu.svelte')
		}
	})
</script>

<header class="relative top-0 z-4 h-[72px] w-full border-b border-border/50 backdrop-blur-sm">
	<nav class="flex h-full items-center px-3 py-2 xl:container" aria-label={m.nav_menu_name()}>
		<a class="link-branding-gradient w-fit rounded-xs text-xl font-bold" href="/">
			{m.name()}
		</a>

		{#if mobileMenu}
			{#await mobileMenu then { default: MobileMenu }}
				<MobileMenu />
			{/await}
		{/if}

		<div
			class="ml-auto hidden h-full items-center gap-5 md:flex"
			class:mr-auto={$page.url.pathname === '/'}
		>
			<div class="flex items-center gap-4">
				<a
					class="flex items-center justify-center rounded-xs text-muted-foreground transition-colors hover:text-foreground"
					aria-label={m.socials_twitter()}
					href="https://twitter.com/chainspect_app"
					rel="noopener"
					target="_blank"
					on:click={() => {
						phg?.capture('twitter_link_click', {
							place: 'header_icon',
						})
					}}
				>
					<Twitter height="18" width="18" />
				</a>

				<a
					class="flex items-center justify-center rounded-xs text-muted-foreground transition-colors hover:text-foreground"
					aria-label={m.socials_discord()}
					href="https://discord.gg/nnmJf56XYU"
					rel="noopener"
					target="_blank"
					on:click={() => {
						phg?.capture('discord_link_click', {
							place: 'header_icon',
						})
					}}
				>
					<Discord height="23" width="23" />
				</a>
			</div>

			<Separator class="h-6" orientation="vertical" />

			<ul class="hidden gap-6 md:flex">
				<li>
					<a
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/dashboard'
							? 'text-foreground'
							: 'text-muted-foreground'}"
						href="/dashboard"
						on:click={() => {
							if ($page.url.pathname === '/') {
								phg?.capture('open_dashboard', {
									place: 'header_link',
								})
							}
						}}
					>
						{m.link_dashboard()}
					</a>
				</li>

				<li>
					<a
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/compare'
							? 'text-foreground'
							: 'text-muted-foreground'}"
						href="/compare"
					>
						{m.link_compare()}
					</a>
				</li>

				<li>
					<a
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/blog'
							? 'text-foreground'
							: 'text-muted-foreground'}"
						href="/blog"
					>
						{m.link_blog()}
					</a>
				</li>

				<li>
					<a
						class="rounded-xs bg-transparent text-center text-sm hover:text-foreground {$page.url
							.pathname === '/about'
							? 'text-foreground'
							: 'text-muted-foreground'}"
						href="/about"
					>
						{m.link_about()}
					</a>
				</li>

				<li>
					<button
						class="rounded-xs bg-transparent text-center text-sm text-muted-foreground hover:text-foreground"
						on:click={() => {
							getListedDialogStore.set({ open: true })

							phg?.capture('get_listed_dialog_open', {
								place: 'header',
							})
						}}
					>
						{m.link_get_listed()}
					</button>
				</li>
			</ul>
		</div>

		{#if $page.url.pathname === '/'}
			<div class="hidden md:block">
				<Button
					href="/dashboard"
					variant="secondary"
					on:click={() => {
						phg?.capture('open_dashboard', {
							place: 'header_button',
						})
					}}
				>
					{m.dashboard_cta()}
				</Button>
			</div>
		{/if}
	</nav>
</header>
