<script lang="ts">
	import { Dialog as DialogPrimitive } from 'bits-ui'

	import { cn } from '$lib/utils/shadcn'

	type $$Props = DialogPrimitive.DescriptionProps

	let className: $$Props['class'] = undefined
	export { className as class }
</script>

<DialogPrimitive.Description
	class={cn('text-center text-sm text-muted-foreground sm:text-left', className)}
	{...$$restProps}
>
	<slot />
</DialogPrimitive.Description>
