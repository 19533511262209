<script lang="ts">
	import { Button } from '$lib/components/ui/button'
	import * as m from '$lib/paraglide/messages'
	import { getListedDialogStore } from '$lib/stores/dialogs'
	import { phg } from '$lib/utils/posthog'
</script>

<footer
	class="mt-auto flex items-center justify-between gap-3 border-t border-border/50 bg-background py-6 sm:border-t-0 sm:py-10"
>
	<nav class="flex w-full flex-col gap-10 px-3 xl:container">
		<div class="flex flex-col items-start justify-between gap-4 xs:flex-row xs:items-center">
			<a class="link-branding-gradient w-fit rounded-xs text-xl font-bold xs:text-2xl" href="/">
				{m.name()}
			</a>

			<Button
				size="sm"
				variant="outline"
				on:click={() => {
					getListedDialogStore.set({ open: true })

					phg?.capture('get_listed_dialog_open', {
						place: 'footer',
					})
				}}
			>
				{m.get_listed_cta()}
			</Button>
		</div>

		<ul
			class="grid grid-cols-2 gap-x-1 gap-y-10 px-1 text-sm xs:text-base md:grid-cols-3 md:gap-10"
		>
			<li>
				<p class="font-semibold">
					{m.footer_resources()}
				</p>

				<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="/dashboard"
						>
							{m.link_dashboard()}
						</a>
					</li>

					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="/compare"
						>
							{m.link_compare()}
						</a>
					</li>

					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="/blog"
						>
							{m.link_blog()}
						</a>
					</li>

					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="/about"
						>
							{m.link_about()}
						</a>
					</li>
				</ul>
			</li>

			<li>
				<p class="font-semibold">
					{m.footer_community()}
				</p>

				<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="https://twitter.com/chainspect_app"
							rel="noopener"
							target="_blank"
							on:click={() => {
								phg?.capture('twitter_link_click', {
									place: 'footer_text',
								})
							}}
						>
							{m.socials_twitter()}
						</a>
					</li>

					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="https://discord.gg/nnmJf56XYU"
							rel="noopener"
							target="_blank"
							on:click={() => {
								phg?.capture('discord_link_click', {
									place: 'footer_text',
								})
							}}
						>
							{m.socials_discord()}
						</a>
					</li>
				</ul>
			</li>

			<li>
				<p class="font-semibold">
					{m.footer_contacts()}
				</p>

				<ul class="mt-3 flex flex-col gap-3 xs:mt-4 xs:gap-4">
					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="https://t.me/chainspect_app"
							rel="noopener"
							target="_blank"
						>
							{m.socials_telegram()}
						</a>
					</li>

					<li>
						<a
							class="max-w-[180px] truncate rounded text-sm text-muted-foreground hover:text-foreground"
							href="mailto:contact@chainspect.app"
							rel="noopener"
							target="_blank"
						>
							{m.social_email()}
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</nav>
</footer>
