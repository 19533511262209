<script lang="ts">
	import { onMount } from 'svelte'
	import { cubicOut } from 'svelte/easing'
	import { tweened } from 'svelte/motion'

	import { navigationStore } from '$lib/stores/navigation'

	const progress = tweened(0, { easing: cubicOut })
	const opacity = tweened(1, { easing: cubicOut })

	const unsubscribe = navigationStore.subscribe(state => {
		if (state === 'loading') {
			opacity.set(1, { duration: 0 })
			progress.set(0.7, { duration: 3500 })
		} else if (state === 'loaded') {
			const duration = 1000

			progress.set(1, { duration })
			opacity.set(0, { duration: duration / 2, delay: duration / 2 })

			setTimeout(() => {
				progress.set(0, { duration: 0 })
			}, duration)
		}
	})

	onMount(() => () => {
		unsubscribe()
	})
</script>

<div style="opacity: {$opacity}" class="pointer-none fixed left-0 right-0 top-0 z-5 h-1">
	<div style="width: {$progress * 100}%" class="h-full bg-branding-1" />
</div>
