<script lang="ts">
	import { partytownSnippet } from '@builder.io/partytown/integration'
	import { ParaglideJS } from '@inlang/paraglide-sveltekit'
	import { onMount } from 'svelte'
	import { MetaTags } from 'svelte-meta-tags'

	import { afterNavigate, beforeNavigate } from '$app/navigation'
	import { page, navigating } from '$app/stores'
	import GetListedDialog from '$lib/components/dialogs/get-listed-dialog.svelte'
	import MetricDialog from '$lib/components/dialogs/metric-dialog.svelte'
	import LoadingBar from '$lib/components/loading-bar.svelte'
	import { i18n } from '$lib/i18n'
	import AppFooter from '$lib/layouts/app-footer.svelte'
	import AppHeader from '$lib/layouts/app-header.svelte'
	import * as m from '$lib/paraglide/messages'
	import { navigationStore } from '$lib/stores/navigation'
	import { detectDevice, detectDeviceType, phg } from '$lib/utils/posthog'

	import type { LayoutData } from './$types'

	import '../styles/app.css'

	export let data: LayoutData

	onMount(() => {
		phg?.identify(data.userId, {
			$device_type: detectDeviceType(window.navigator.userAgent),
			$device: detectDevice(window.navigator.userAgent),
		})

		window.addEventListener('vite:preloadError', () => {
			window.location.reload()
		})

		if (import.meta.env.MODE === 'production') {
			console.info(
				`%cChainspect\n%cHi! Do you need our data? Contact us by email contact@chainspect.app or in Telegram @chainspect_app and we'll try to help you with the data you need!`,
				`
				font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				font-size: 32px;
				font-weight: 600;
				line-height: 1.5;
				background: linear-gradient(to right, rgb(2, 125, 253), rgb(27, 230, 171));
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			`,
				`
				font-family: Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
				padding: 10px;
				font-size: 14px;
				line-height: 1.2;
				background: rgb(31, 41, 55);
				color: rgb(255, 255, 255);
			`
			)
		}
	})

	$: $navigationStore = $navigating !== null ? 'loading' : 'loaded'

	afterNavigate(() => {
		phg?.capture('$pageview', {
			$device_type: detectDeviceType(window.navigator.userAgent),
			$device: detectDevice(window.navigator.userAgent),
		})
	})

	beforeNavigate(navigation => {
		phg?.capture('$pageleave', {
			$prev_pageview_pathname: navigation.from?.url.pathname,
			$device_type: detectDeviceType(window.navigator.userAgent),
			$device: detectDevice(window.navigator.userAgent),
		})
	})
</script>

<svelte:head>
	<script>
		// Forward the necessary functions to the web worker layer
		partytown = {
			forward: ['dataLayer.push', 'gtag'],
		}
	</script>

	<!-- eslint-disable-next-line svelte/no-at-html-tags @typescript-eslint/no-unused-expressions -->
	{@html '<script>' + partytownSnippet() + '</script>'}

	<script
		src="https://www.googletagmanager.com/gtag/js?id=G-7V8P7RN34Z"
		type="text/partytown"
	></script>
	<script type="text/partytown">
		window.dataLayer = window.dataLayer || []
		window.gtag = function () {
			dataLayer.push(arguments)
		}
		gtag('js', new Date())
		gtag('config', 'G-7V8P7RN34Z')
	</script>
</svelte:head>

<ParaglideJS {i18n}>
	<MetaTags
		canonical={$page.data.seoTags?.canonical ?? 'https://chainspect.app/'}
		description={$page.data.seoTags?.description ?? m.seo_base_description()}
		openGraph={{
			url: $page.data.seoTags?.canonical ?? 'https://chainspect.app/',
			title: $page.data.seoTags?.title ?? m.seo_base_title(),
			description: $page.data.seoTags?.description ?? m.seo_base_description(),
			type: $page.data.seoTags?.type ?? 'website',
			images: [
				{
					url: $page.data.seoTags?.image ?? 'https://chainspect.app/og',
					width: 1200,
					height: 630,
				},
			],
			siteName: 'Chainspect',
			article: $page.data.seoTags?.article
				? {
						...$page.data.seoTags.article,
						authors: ['https://twitter.com/chainspect_app'],
					}
				: {},
		}}
		title={$page.data.seoTags?.title ?? m.seo_base_title()}
		twitter={{
			handle: '@chainspect_app',
			site: '@chainspect_app',
			cardType: 'summary_large_image',
			title: $page.data.seoTags?.title ?? m.seo_base_title(),
			description: $page.data.seoTags?.description ?? m.seo_base_description(),
			image: $page.data.seoTags?.image ?? 'https://chainspect.app/og',
		}}
	/>

	<LoadingBar />

	<AppHeader isMobile={data.isMobile} />

	<slot />

	<AppFooter />

	<GetListedDialog />

	<MetricDialog />
</ParaglideJS>
